<template>
  <v-card
    flat
    class="pa-3 mt-2"
  >
    <v-card-text class="d-flex">
      <v-img
        class="me-6"
        :max-width="width"
        :max-height="height"
        :v-if="inputVal !== null"
        :src="inputVal"></v-img>

      <div>
        <v-btn
          color="primary"
          class="me-3 mt-5"
          @click="$refs.refInputEl.click()"
        >
          <v-icon class="d-sm-none">
            {{ icons.mdiCloudUploadOutline }}
          </v-icon>
          <span class="d-none d-sm-block">Загрузить фото</span>
        </v-btn>

        <input
          ref="refInputEl"
          type="file"
          accept=".png"
          :hidden="true"
          v-on:="onImageChange"
          v-on:change="onImageChange"
        />

        <v-btn
          color="error"
          outlined
          class="mt-5"
          @click="removeImage"
        >
          Удалить
        </v-btn>
        <p class="text-sm mt-5">
          Выберите PNG файл
        </p>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import { mdiCloudUploadOutline } from '@mdi/js'

export default {
  name: 'ImageUpload',
  props: ['value', 'width', 'height'],
  data: () => ({
    icons: {
      mdiCloudUploadOutline,
    },
  }),
  methods: {
    onImageChange($event) {
      const files = $event.target.files || $event.dataTransfer.files
      this.getFormData(files)
    },
    removeImage() {
      this.$refs.refInputEl.value=null;
      this.inputVal = ''
      this.$emit('file-removed')
    },
    getFormData(files) {
      const data = new FormData()
      if (files[0] !== undefined && files[0] !== null) {
        data.append('data', files[0], files[0].name)
        let that = this
        let blob = files[0].arrayBuffer().then((arrayBuffer) => {
          let blob = new Blob([new Uint8Array(arrayBuffer)], { type: files[0].type })
          let reader = new FileReader()
          reader.readAsDataURL(blob)
          reader.onloadend = function() {
            that.inputVal = reader.result
          }
          return blob
        })
        blob.then(function(value) {
          that.$emit('formData-updated', value)
        })
      }
    },
  },
  computed: {
    inputVal: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      },
    },
  },
}
</script>

<style scoped>

</style>
